module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"outsmartinsight","accessToken":"MC5ZVkhQU0JBQUFDQUE1Zlp5.WO-_vV4LCHfvv73vv70yNip7NQkCW--_ve-_vVrvv73vv73vv73vv71B77-977-977-977-977-9RRw_","promptForAccessToken":true,"apiEndpoint":"https://outsmartinsight.cdn.prismic.io/api/v2","lang":"*","pageSize":100,"imageImgixParams":{"auto":"compress,format","fit":"max","q":50},"imagePlaceholderImgixParams":{"w":100,"blur":15},"toolbar":"new"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PHWFQTJN","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Outsmart Insight","short_name":"Outsmart Insight","start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","display":"standalone","icon":"src/images/icon.png","crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"4e882bc3ee892b4f17be32bbb9577091"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
