exports.linkResolver = (doc) => {
    if (doc.type === 'customer_stories_index') {
        return `/${doc.uid}/`
    }
    
    if (doc.type === 'customer_story') {
        return `/customer-stories/${doc.uid}/`
    }
    
    if (doc.type === 'generic_page') {
        return `/${doc.uid}/`
    }
    
    if (doc.type === 'insight_article') {
        return `/our-insights/${doc.uid}/`
    }
    
    if (doc.type === 'our_insight_index') {
        return `/${doc.uid}/`
    }

    if (doc.type === 'careers') {
        return `/${doc.uid}/`
    }

    if (doc.type === 'job_description') {
        return `/careers/${doc.uid}/`
    }
    
    if (doc.type === 'project_index') {
        return `/${doc.uid}/`
    }
    
    if (doc.type === 'service_index') {
        return `/${doc.uid}/`
    }
    
    if (doc.type === 'service') {
        return `/services/${doc.uid}/`
    }

    if (doc.type === 'solution_index') {
        return `/${doc.uid}/`
    }
    
    if (doc.type === 'solution') {
        return `/solutions/${doc.uid}/`
    }
    
    if (doc.type === 'contact') {
        return `/${doc.uid}/`
    }

    if (doc.type === 'advisors-register_personal_details') {
        return `/advisors/`
    }

    if (doc.type === 'advisors-request_payment') {
        return `/advisors/request-payment/`
    }

    return '/'
}